@import '../../Themes/_colors.scss';

.completed_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 60px 0;

  .text {
    color: green;
    font-size: 24px;
    font-weight: bold;
  }
}

.progress_bar_container {
  margin: 20px 0 60px;

  .title {
    font-size: 22px;
    font-weight: bold;
  }

  .subtitle {
    margin-top: 16px;
    font-size: 14px;
  }

  .progress_bar {
    width: 300px;
    height: 8px;
    border-radius: 4px;
    background-color: #ccc;
    position: relative;
    overflow: hidden;

    > span {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: coral;
    }
  }
}
