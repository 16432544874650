@import '../../Themes/_colors.scss';

.card {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    padding: 20px;
    width: fit-content;
    border-radius: 15px;
    margin: 15px;
  }

  &__divider {
    border: 1px solid black;
  }

  &__message-bottom {
    text-align: left;
  }

  &__message-top {
    text-align: left;
  }

  &__message--text-error {
    color: $errorText;
  }

  &__message--text-success {
    color: $successText;
  }
}
