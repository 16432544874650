.cancel-recurring-form-wrapper {
  padding: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;

  button,
  form {
    margin-bottom: 0;
  }

  .user-file {
    margin-top: 15px;
  }

  input[type='file'] {
    margin-bottom: 10px;
  }

  .message-box {
    border: 4px solid #f77462;
    display: inline-block;
    padding: 14px;
    margin-top: 26px;
    font-weight: bold;
  }

  .submit-button {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 20px;
    flex-wrap: wrap;

    button:first-child {
      margin-right: 6px;
    }
  }
}
