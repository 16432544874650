@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');
@import '../../Themes/_colors.scss';

.file-uploader {
  &__browse-link-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
    margin-left: 15px;
  }

  &__browse-link-text {
    font-size: 0.9em;
    text-decoration: black underline;
  }

  &__dropzone-icon {
    font-size: 2em;
    margin: 5px;
    color: lightgrey;
  }

  &__dropzone-text {
    font-size: 2em;
    color: lightgrey;
  }

  &__upload-button-container {
    display: flex;
    justify-content: center;
  }
}

#gallery {
  margin-bottom: 30px;

  button {
    // Override global button styles on file upload cancel button
    padding: 0;
    background-color: transparent;
  }

  .fine-uploader-dropzone-container {
    text-align: center;
    margin: auto;
  }

  .react-fine-uploader-file-input-container {
    align-self: flex-start;
  }

  .react-fine-uploader-gallery-dropzone {
    display: flex;
    flex-direction: column;
  }

  .react-fine-uploader-gallery-file-input-container {
    background-color: transparent;
    border: 0;
    align-self: flex-start;
  }

  .react-fine-uploader-gallery-files {
    align-self: flex-start;
  }

  .react-fine-uploader-gallery-file-input-content {
    color: black;
  }
}
