.cancel-recurring-gifts {
  display: flex;
  flex-direction: column;

  &__table-view {
    display: flex;
    flex-direction: column;
  }

  &__message {
    margin-bottom: 16px;
  }
}
