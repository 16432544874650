@import '../../../Themes/_colors.scss';

.help-container {
  float: right;
}

.help-container {
  color: $teal;
  align-self: center;

  &__link {
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
      color: $darkBlue;
    }
  }
}

i.fa.fa-question-circle.fa-2 {
  margin-right: 5px;
}
